import React, { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

function Page(_props) {
  return (
    <Layout>
      <h1>Bedrift og ergonomi – Bedriftsavtale</h1>

      <p>
        Over halvparten av alle sykemeldinger er pga. muskel og skjelettplager. Kiropraktor Akutten Stavanger/Sandnes
        kan hjelpe din bedrift i å få ned sykefraværet og på sikt spare penger. Flere studier viser at bedrifter i løpet
        av få måneder sparer inn pengene som brukes til behandling av ansatte i å få et redusert sykefravær.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ul className="!my-0 md:!my-2">
          Vi tilbyr flere tjenester for din bedrift. En bedriftsavtale med Kiropraktor Akutten Stavanger/Sandnes kan gi
          deg og dine ansatte:
          <li>Redusert pris på kiropraktor behandling og personlig trening i både Stavanger og Sandnes.</li>
          <li>
            Skreddersydde avtaler der vi også kan komme å behandle på arbeidsplassen og gjøre ergonomisk vurdering på de
            ansatte
          </li>
          <li> Vi tilbyr også personlig trening som en del av det å holde dine ansatte friske</li>
        </ul>
        <StaticImage
          src="../images/office-desk.jpg"
          alt="Trening"
          layout="fullWidth"
          aspectRatio={1.5}
          // height={200}
          width={150}
          objectFit="cover"
          className="mx-auto md:mt-4 w-full max-w-sm rounded-lg shadow-md"
          imgClassName="rounded-lg"
        />
      </div>

      <p>
        Vi tilbyr også gjennomgang av ergonomien i din bedrift. Sittestilling har stor påvirkning på muskelspenninger og
        leddbevegelighet.
      </p>

      <p>
        Om hodet dras fremover mot skjermen, som kanskje er det mest klassiske problemet, så krummes ryggen. Musklene i
        nakken må mangedoble spenningene for å holde imot vekten av hodet som havner foran kroppen.
      </p>

      <p>
        Leddene i ryggen tilpasser seg og stivner til i denne fremover krummede stillingen og resultatet er stive ledd
        og spente muskler. Dette vil over tid skape strukturelle forandringer i skjelettet ditt som slitasje og krum
        rygg. Både de stive leddene og stive musklene vil etter hvert begynne å gi smerter.
      </p>

      <p>
        Kiropraktor Akutten Stavanger/Sandnes kan hjelpe deg med å komme med enkle tips om sittestillingen til de
        ansatte som vil gi en enorm effekt både på eksisterende muskel- og skjelettplager og også forebygge fremtidige
        problemer.
      </p>

      <p>
        Alle vet hvor kostbart det er for en bedrift om en ansatt blir sykemeldt. En ergonomisk gjennomgang i din
        bedrift vil ikke bare være gunstig for helsen til dine ansatte, men også for økonomien til din bedrift.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <StaticImage
          src="../images/office-view.jpg"
          alt="Trening"
          layout="fullWidth"
          aspectRatio={1.5}
          // height={200}
          width={150}
          objectFit="cover"
          className="mx-auto md:mt-4 w-full max-w-sm rounded-lg shadow-md"
          imgClassName="rounded-lg"
        />
        <ul className="!my-0 md:!my-2">
          En ergonomisk gjennomgang fra Kiropraktor Akutten Stavanger/Sandnes innebærer:
          <li>
            Kiropraktoren lærer dine ansatte om optimal ergonomisk sittestilling. Alle elementer tas hensyn til fra
            posisjon på rygg og nakke til vinkel i hofter og knær.
          </li>
          <li>
            Kiropraktoren gir enkle styrke og tøyningsøvelser som raskt kan gjøres i løpet av arbeidsdagen. Dette er
            både generelle holdningskorrigerende øvelser og spesialtilpassede øvelser ut i fra den enkeltes behov.
          </li>
          <li>
            Kiropraktoren gir tips til arbeidsgiver i forhold til ulike kontormøbler som bidrar til en bedre ergonomi i
            bedriften der vi også kan skreddersy i forhold til behovet til den enkelte ansatte.
          </li>
          <li>Kiropraktoren tilbyr også et kort powerpoint-foredrag om optimal ergonomi.</li>
          <li>
            Kiropraktor Akutten Stavanger/Sandnes samarbeider med Magnar Eikeland og kan igjennom dem tilby gunstige
            priser på kontormøbler.
          </li>
        </ul>
      </div>

      <p className="font-semibold">
        Ring Kiropraktor Akutten Stavanger/Sandnes på tlf 45281030 for å få en pris på en ergonomisk gjennomgang i din
        bedrift!
      </p>
    </Layout>
  )
}

export default Page
